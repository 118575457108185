import request from '../../utils/axios';
import { getIpConfig } from '../../utils/IMEI';
var baseUrl = ''
if (location.origin.indexOf('localhost') > -1) {
    // baseUrl = 'https://testwww.rlc525.com/api/proxy'
    baseUrl = '/testapi'+'/api/proxy'
} else if (location.origin.indexOf('rlc525') > -1) {
    baseUrl = 'https://testwww.rlc525.com/api/proxy'
} else {
    baseUrl = window.location.origin
}
//baseUrl = 'https://www.rlcvipltd.net/api/proxy'
// 发送验证码
export function sendCode(data) {
    return request({
        url: baseUrl + '/uc/api/proxy/v4/U001',
        method: 'POST',
        data
    })
}
//注册
export async function register(data) {
    data.IsMt5 = true;
    const ipInfo = await getIpConfig();
    data = {...ipInfo, ...data};
    return request({
        url: baseUrl + '/uc/api/proxy/v4/U101',
        method: 'POST',
        data,
        headers: {
          platform: 1
        }
    })
}
//手机号码是否已注册
export function rulerPhone(data) {
    return request({
        url: baseUrl + '/uc/api/proxy/v4/O102?mobile=' + data,
        method: 'get',
    })
}
//登录
export function login(data) {
    return request({
        url: baseUrl + '/uc/api/proxy/v4/U103',
        method: 'POST',
        data
    })
}
//根据token获取用户资料
export function getUserByToken(data) {
    return request({
        // url: baseUrl + '/uc/api/proxy/v4/CUT100',
        url: baseUrl + '/uc/api/proxy/v4/UI101?account=1',
        method: 'get',
        headers: {
            'access-key': data.replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+')
        }
    })
}